export type PlacementDataValue = string | number | string[] | number[];

export type PlacementDetails = {
  [key: string]: PlacementDataValue;
};

export type PlacementId = string;

export interface Placement {
  id: PlacementId;
  name: string;
  lat: number;
  lng: number;
  boundaries?: { [boundaryType: string]: string[] };
  details: PlacementDetails;
  eligibility: Eligibility;
}

/**
 * There are 3 possible values for Placement.eligibility:
 * 1. not-applicable: is when eligibility is not applicable for this placement.
 *    This is configured via data sheet and UIConfig (eligibility.schoolEligibility)
 * 2. eligible: is when eligibility is applicable, and user is eligible for placement
 * 3. not-eligible: is when eligibility is applicable, and user is NOT eligible for for this placement
 */
export type Eligibility = "not-applicable" | "eligible" | "not-eligible";

export function isEligible(placement: Placement): boolean {
  switch (placement.eligibility) {
    case "eligible":
      return true;
    case "not-eligible":
      return false;
    case "not-applicable":
      // If eligibility check is not applicable for this placement, we should treat it as ineligible
      return false;
  }
}

export function isApplicableForEligibilityCheck(placement: Placement): boolean {
  switch (placement.eligibility) {
    case "eligible":
    case "not-eligible":
      return true;
    case "not-applicable":
      return false;
  }
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { Header } from "./Header";
import { Main } from "./Main";
import { TopLevelLayout } from "../components/layout/TopLevelLayout";
import { fetchPlacements } from "../store/placements";
import { setConfig } from "../store/config";
import { RootState } from "../store";
import { setResults } from "../store/results";
import { setDistanceToPlacementById } from "../store/distance";
import { useLocaleMessages } from "../hooks/useLocaleMessages";
import { usePlacementResults } from "../hooks/usePlacementResults";
import { calculateDistanceToPlacementById } from "../services/distance";
import { useSyncRouteToRedux } from "../hooks/useSyncRouteToRedux";
import { UIConfig } from "../types/UIConfig";
import { Analytics } from "./Analytics";
import { setOrganization } from "../store/organization";
import { Organization, DEFAULT_ORGANIZATION_PATH } from "../types/Organization";

interface AppProps {
  initialConfig: UIConfig;
  organization: Organization;
}

export const App = ({
  initialConfig,
  organization,
}: AppProps): React.ReactElement | null => {
  useSyncRouteToRedux();
  const placements = useSelector((state: RootState) => state.placements);
  const config = useSelector((state: RootState) => state.config);
  const results = useSelector((state: RootState) => state.results);
  const address = useSelector((state: RootState) => state.address);
  const lang = useSelector((state: RootState) => state.lang);
  const placementResults = usePlacementResults();
  const messages = useLocaleMessages(lang.locale);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setOrganization(organization));
  }, [dispatch, organization]);

  React.useEffect(() => {
    dispatch(setConfig(initialConfig));
  }, [dispatch, initialConfig]);

  React.useEffect(() => {
    dispatch(
      fetchPlacements({
        placementMapping: initialConfig.placement,
        lang: lang.locale,
        organizationPath: organization.path,
        eligibilityConfig: initialConfig.eligibility,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig, lang, organization]);

  const placementIds = React.useMemo(() => {
    if (placements.status !== "finished") return [];
    return Object.keys(placements.byId);
  }, [placements]);

  React.useEffect(() => {
    if (placements.status !== "finished") return;
    dispatch(setResults(placementIds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, placementIds.length, placements.status]);

  React.useEffect(() => {
    if (results.status !== "finished" || placementResults === null) return;
    dispatch(setResults(placementResults.map((p) => p.id)));
  }, [dispatch, placementResults, results.status]);

  React.useEffect(() => {
    if (
      placements.status === "finished" &&
      address.status === "finished" &&
      config.state === "finished"
    ) {
      const distanceToPlacementById = calculateDistanceToPlacementById(
        address.values,
        placements.byId,
        config.values
      );
      dispatch(setDistanceToPlacementById(distanceToPlacementById));
    }
  }, [dispatch, placements, address, config]);

  var icon = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
  var appleTouchIcon = document.querySelector<HTMLLinkElement>(
    "link[rel~='apple-touch-icon']"
  );
  var manifest = document.querySelector<HTMLLinkElement>(
    "link[rel~='manifest']"
  );
  const meta = document.querySelector<HTMLMetaElement>(
    "meta[name~='description']"
  );

  const prefix =
    organization.path === DEFAULT_ORGANIZATION_PATH
      ? ""
      : `${organization.path}-`;

  if (icon) icon.href = `/${prefix}favicon.ico`;
  if (appleTouchIcon) appleTouchIcon.href = `/${prefix}logo192.png`;
  if (manifest) manifest.href = `/${prefix}manifest.json`;
  if (meta) meta.content = organization.title;

  React.useEffect(() => {
    document.title = organization.title;
  }, [organization]);

  return (
    <IntlProvider locale={lang.locale} messages={messages}>
      <TopLevelLayout header={<Header />} main={<Main />} />
      <Analytics />
    </IntlProvider>
  );
};
